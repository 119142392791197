import firebase from "firebase/compat/app";
import 'firebase/compat/auth';

const app = firebase.initializeApp({
   apiKey: "AIzaSyAh6F_kNGQpeip33hA4Jdab7HoF4JC5jjk",
   authDomain: "athleteium-dev.firebaseapp.com",
   projectId: "athleteium-dev",
   storageBucket: "athleteium-dev.appspot.com",
   messagingSenderId: "339570535704",
   appId: "1:339570535704:web:b2b2f43999226152abc26dd",
   databaseURL: "https://athleteium-dev-default-rtdb.europe-west1.firebasedatabase.app",
});

export const emailPasswordAuth = app.auth();
export const googleAuth = async () => {
   const provider = new firebase.auth.GoogleAuthProvider()

   return await firebase.auth().signInWithPopup(provider);
}

export const errorMessages = {
   "auth/wrong-password": "user or password is wrong",
   "auth/user-not-found": "user not exist"
}

export default firebase;