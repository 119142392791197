import styled from 'styled-components';
import logoImage from '../../assets/logo1.png';
import {useObserver} from "mobx-react-lite";
import {useRootStore} from "../../store/root.store";

const MaintenancePage = styled.div`
  .container {
    text-align: center;
    padding: 20px;
    color: #333;
  }

  h1 {
    font-size: 5rem;
  }

  p {
    font-size: 1.8rem;
  }

  .article {
    display: block;
    text-align: left;
    max-width: 650px;
    margin: 0 auto;

    img {
      width: 200px;
    }
  }

  a {
    color: #ff9201;
    text-decoration: none;
  }

  a:hover {
    color: #333;
    text-decoration: none;
  }
  
  @media only screen and (max-width: 600px) {
    .article {
      text-align: center;

      img {
        width: 100px;
      }
    }

    h1 {
      font-size: 2.8rem;
      margin-bottom: 10px;
    }

    p {
      font-size: 1.4rem;
    }
  }
`;

export default function Welcome() {
	const { userStore } = useRootStore();

	return useObserver(() => (
		<>
			{userStore.user && (
				<MaintenancePage>
					<div className="container">
						<div className="article">
							<img src={logoImage} alt={"logo"}/>
							<h1>We&rsquo;ll be back soon!</h1>
							<div>
								<p>
									Sorry for the inconvenience but we&rsquo;re performing some
									maintenance at the moment. If you need to you can always{' '}
									<a href="mailto:info@athleteium.com">contact us</a>, otherwise
									we&rsquo;ll be back online shortly!
								</p>
								<br/>
								<p>&mdash; Athleteium Team</p>
							</div>
						</div>
					</div>
				</MaintenancePage>
			)}
		</>
	))
}
