import {keyframes} from "styled-components";

export const fadeInSignInLogoFromTopAnimation = keyframes`
  from {
    opacity: 0;
    transform: translate(-50%, -50px);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -33px);
  }
`;

export const fadeInSignInContainerFromBottom = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  } 
`;