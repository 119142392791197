import styled from "styled-components";

export const StyledButton = styled.button`
  cursor: pointer;
  border-radius: 20px;
  border: 1px solid #ff9201;
  background-color: #ff9201;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;

  &:active:not(&:disabled) {
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
  }

  &.ghost {
    background-color: transparent;
    border-color: #ffffff;
  }
		
		&:disabled {
				opacity: .6;
		}

`;

export default function Button({type, children, ...rest}) {
	return (
		<StyledButton
			type={type}
			{...rest}>
			{children}
		</StyledButton>)
}

Button.defaultProps = {
	type: 'button'
}