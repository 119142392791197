import UserStore from "./user.store";
import {createContext, useContext} from "react";

class Root {
    constructor() {
        this.userStore = new UserStore(this);
    }
}

const RootStoreContext = createContext(new Root());

export const useRootStore = () => useContext(RootStoreContext);