import styled from "styled-components";

export const StyledSocialReactIcon = styled.span`
  svg {
    width: 100%;
    height: 100%;
  }
`;

export default function SocialReactIcon({ icon, ...rest }) {
    return (
        <>
            {icon && <StyledSocialReactIcon { ...rest }>{icon()}</StyledSocialReactIcon>}
        </>
    )
}