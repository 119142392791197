import styled from "styled-components";

export const StyledForm = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  text-align: center;

  .inputs-wrapper {
    input { width: 100%; }
    
    & > *:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

export default function Form({ children, ...rest }) {
    return (
        <StyledForm className="basic-form" {...rest} >
            <div className='inputs-wrapper'>{children}</div>
        </StyledForm>
    )
}