import styled from 'styled-components';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from '../../../containers/Container';
import { StyledContainer } from '../../../containers/Container/Container';
import football from '../../../assets/images/americanFootball.png';
import soccer from '../../../assets/images/soccer.png';
import baseball from '../../../assets/images/baseball.png';

const settings = {
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3500,
  speed: 1000,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const StyledSlider = styled.div`
  ${StyledContainer} {
    padding: 20px 0;
    box-shadow: none;
    background-color: transparent;
  }

  .slick-track {
    flex-direction: row;
  }

  .slide-wrapper {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }

    p {
      font-size: 1.3rem;
      width: 50%;
      margin: 0 auto;
      color: #fff;
      font-weight: 500;
    }
  }
`;

const content = [
  {
    alt: 'football',
    image: football,
    text: 'I owe Athleteium so much! they are the reason I got scouted!',
  },
  {
    alt: 'soccer',
    image: soccer,
    text: 'Finally, a platform to search and assess players from all over the world',
  },
  {
    alt: 'baseball',
    image: baseball,
    text: 'The athletes profile is just what we need as scouters, its just a great platform',
  },
];

export default function SignupSlider() {
  return (
    <StyledSlider>
      <Container noBorderRadius>
        <Slider {...settings}>
          {content.map((slide) => (
            <div className="slide-wrapper" key={slide.alt}>
              <img src={slide.image} alt={slide.alt} />
              <p>{slide.text}</p>
            </div>
          ))}
        </Slider>
      </Container>
    </StyledSlider>
  );
}
