import {emailPasswordAuth, googleAuth} from "../firebase";
import {setNewUser} from "../db/userDB";
import {useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {useRootStore} from "../store/root.store";

export default function useAuth() {
	const navigate = useNavigate();
	const {userStore} = useRootStore();

	async function signupWithEmailAndPassword(email, pass) {
		return await emailPasswordAuth.createUserWithEmailAndPassword(email, pass);
	}

	async function signInWithEmailAndPassword(email, pass) {
		return await emailPasswordAuth.signInWithEmailAndPassword(email, pass);
	}

	async function signupWithGoogle() {
		await googleAuth()
			.then(async res => {
				const {user: {providerData, metadata, uid: userId}} = res;

				await setNewUser(userId, {
					...metadata,
					...providerData[0]
				});
			})
	}

	useEffect(() => {
		if (userStore.user) {
			navigate('/');
		}
	}, [userStore.user])

	return {signupWithEmailAndPassword, signupWithGoogle, signInWithEmailAndPassword};
}