import {create, test} from "vest";
import customEnforce from "../../utils/formValidations";

export const signUpScheme = create((data = {}) => {
// EMAIL
	test('email', 'Invalid Email', () => {
		customEnforce(data.email).isValidEmail();
	});

	// PASSWORD
	test('password', 'Password must be at least 8 characters long', () => {
		customEnforce(data.password).longerThan(7);
	});

	// test('password', 'Password must involve Numbers, Lowercase and Uppercase characters', () => {

	// });

	// CONFIRM PASSWORD
	test('confirmPassword', 'Confirm password invalid', () => {
		customEnforce(data.confirmPassword).isNotBlank();
	});

	test('confirmPassword', 'Confirm password must be the same as password', () => {
		customEnforce(data.confirmPassword).condition(pass => pass === data.password);
	});
})