import styled from "styled-components";
import {forwardRef} from "react";

const StyledGenericInput = styled.input`
  font-size: 1.6rem;
  background-color: #eee;
  border: 0.5px solid #D4D4D4;
  border-radius: 6px;
  width: 100%;
  padding: 10px;
  transition: .2s all;

  &:focus {
    outline: none;
    background-color: #fff;
  }
`;

const Generic = forwardRef((props, ref) => {
    return <StyledGenericInput {...props} ref={ref}/>
});

Generic.displayName = 'Generic';

export default Generic;