import styled from "styled-components";
import {useForm} from "react-hook-form";
import {vestResolver} from '@hookform/resolvers/vest';
import {signUpScheme} from "./schemes";

import colors from "../../styles/colors";
import {fadeInSignInContainerFromBottom, fadeInSignInLogoFromTopAnimation} from "../../styles/animations";

import {FcGoogle} from "react-icons/fc";
import logo from '../../assets/logo2.png';

import {Container} from "../../containers/Container";
import Button from "../../components/Button";
import Input from '../../components/Input';
import Form from "../../containers/Form";
import SocialReactIcon, {StyledSocialReactIcon} from "../../components/Socials/SocialReactIcon";
import {StyledContainer} from "../../containers/Container/Container";
import {StyledButton} from "../../components/Button/Button";
import useAuth from "../../hooks/useAuth";
import {setNewUser} from "../../db/userDB";
import FormError from "../../components/FormError";
import SignupSlider from "./components/Slider";
import {useObserver} from "mobx-react-lite";
import {Link} from "react-router-dom";

const StyledSignUp = styled.div`
  & > ${StyledContainer} {
    opacity: 0;
    position: relative;
    overflow: initial;
    width: 768px;
    min-height: 500px;
    display: flex;
    justify-content: space-between;
    animation: ${fadeInSignInContainerFromBottom} .7s .2s forwards ease-in-out;

    & > div {
      flex: .5;
      transition: all 0.6s ease-in-out;
      text-align: center;
      box-shadow: none;
    }

    .logo {
      z-index: 1;
      opacity: 0;
      position: absolute;
      width: 74px;
      left: 50%;
      animation: ${fadeInSignInLogoFromTopAnimation} 1s 1s forwards ease-out;
    }

    .form-container {
						font-size: 1.4rem;
      display: flex;
      flex-direction: column;
      justify-content: center;

      & > * {
        padding: 20px;
      }

      .para {
        padding: 20px 0 0;
      }
						
						.login-link {
								box-shadow: none;
						}
    }
  }

  h1 {
    font-weight: bold;
    margin: 0;
  }

  .social-container {

    ${StyledButton} {
      width: 100%;
      border-radius: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover ${StyledSocialReactIcon} {
        right: 88%;
        left: 0;
      }

      ${StyledSocialReactIcon} {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 0;
        bottom: 0;
        background-color: white;
        display: inline-block;
        transition: all .5s ease-out;
      }

      &.google {
        background-color: #4285f4;
        border: 2px solid #4285f4;
      }
    }
  }

  .info-container {
    background-color: ${colors.primaryColor};
    box-shadow: -5px 0 10px rgba(0, 0, 0, .3);
  }
		
		@media only screen and (max-width: 900px) {
    & > ${StyledContainer} {
						max-width: 500px;
						width: 90%;
						margin: 0 auto;
      box-shadow:  0 0 0 10px rgba(255,146,1, .5);
						transition: all .3s;
						
      .info-container {
        display: none;
      }

      .form-container {
        padding-top: 20px;
        flex: 1 !important;
      }
    }
		}
`

export default function Signup() {
	const {register, handleSubmit, formState: {errors}} = useForm({
		resolver: vestResolver(signUpScheme)
	});
	const {signupWithEmailAndPassword, signupWithGoogle} = useAuth();

	const onSubmit = async values => {
		const {email, password,} = values;

		try {
			const {user: {providerData, metadata, uid: userId}} = await signupWithEmailAndPassword(email, password);

			await setNewUser(userId, {
				...providerData[0], ...metadata,
			})
		} catch (err) {
			console.log(err);
		}
	}

	return useObserver(() => {

		return (
			<StyledSignUp>
				<Container flexDirection={"row"}>
					<img className='logo' src={logo} alt='logo'/>
					<Container
						noBorderRadius
						className="form-container">
						<h1>Create Account</h1>
						<div className="social-container">
							<Button className='google' onClick={signupWithGoogle}>
								<SocialReactIcon icon={FcGoogle}/>
								<span>Sign up with google</span>
							</Button>
						</div>

						<p className='para'>or use your email for registration</p>
						{errors && (<div style={{textAlign: "left"}}>
							{Object.values(errors).map(({message, index}) => <FormError key={index} message={message}/>)}
						</div>)}
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Input {...register('email')} placeholder="Email" autoComplete='off'/>
							<Input {...register('password')} type="password" placeholder="Password" autoComplete='off'/>
							<Input {...register('confirmPassword')} type="password" placeholder="Confirm Password" autoComplete='off'/>
							<Button type='submit'>Sign Up</Button>
						</Form>

						<Container
							noBorderRadius
							className={"login-link"}>
							<Link to={"/login"}>
								<p>Click here to login</p>
							</Link>
						</Container>
					</Container>

					<Container
						className="info-container"
						noBorderRadius>
						<SignupSlider/>
					</Container>
				</Container>
			</StyledSignUp>
		)
	});
}
