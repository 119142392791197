import styled from "styled-components";
import { MdReportGmailerrorred } from 'react-icons/md';

const FormErrorStyled = styled.div`
		display: flex;
		gap: 10px;
		
		font-size: 1.3rem;
  color: red;
		
		svg {
				width: 20px;
				height: 20px;
		}
`;

export default function FormError({ message }) {

	return (
		<>
			{message && (
				<FormErrorStyled>
					<span>{MdReportGmailerrorred()}</span>
					<p>{message}</p>
				</FormErrorStyled>
			)}
		</>
	)
}