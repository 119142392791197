import { Navigate } from "react-router-dom";
import {Signup} from "../screens/Singup";
import {Login} from "../screens/Login";
import {Welcome} from "../screens/Welocme";

// const DynamicGuard = (isLoggedIn, Screen = Outlet) => isLoggedIn ? <Screen /> : <Navigate to="/register" />;

const routes = [
	{
		path: '/register',
		element: <Signup/>,
		exact: true
	},
	{
		path: '/login',
		element: <Login/>,
		exact: true
	},
	{
		path: '/welcome',
		element: <Welcome />,
		exact: true
	},
	{
		path: '*',
		element: <Navigate to={"/welcome"} />
	}
];

export default routes;