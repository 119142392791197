import { useEffect } from 'react';
import styled from 'styled-components';
import {useRoutes, useNavigate, useLocation} from 'react-router-dom';
import routes from './router';
import { emailPasswordAuth } from './firebase';
import { useRootStore } from './store/root.store';
import { useObserver } from 'mobx-react-lite';

const StyledApp = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const allowedRoutes = ["/login", "/register"];

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { userStore } = useRootStore();
  const routing = useRoutes(routes);

  useEffect(() => {
    return emailPasswordAuth.onAuthStateChanged(async (user) => {
      if (user) {
        const {
          email,
          photoURL,
          metadata,
          providerData: [provider],
          uid,
        } = user;

        userStore.setAuthUser({
          email,
          photoURL,
          metadata,
          provider,
          uid,
        });
      } else {
        !allowedRoutes.includes(pathname) && navigate('/login');
      }
    });
  }, []);

  return useObserver(() => <StyledApp>{routing}</StyledApp>);
}

export default App;
