import styled from "styled-components";

export const StyledContainer = styled.div`
  background-color: #fff;
  border-radius: ${props => props.noBorderRadius ? 0 : "10px"};
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  position: relative;
  overflow: hidden;
  flex-direction: ${props => props.flexDirection || "column"};
`;

export default function Container({ className, children, ...rest }) {
    return <StyledContainer className={className} {...rest}>
        {children}
    </StyledContainer>
}