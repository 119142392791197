import {create, test} from "vest";
import customEnforce from "../../utils/formValidations";

export const loginScheme = create((data = {}) => {
// EMAIL
	test('email', 'Invalid Email', () => {
		customEnforce(data.email).isValidEmail();
	});

	// PASSWORD
	test('password', 'Password must be at least 8 characters long', () => {
		customEnforce(data.password).isNotEmpty;
	});
})