import { makeAutoObservable } from "mobx";

class UserStore {
    user = null;

    constructor() {
        makeAutoObservable(this);
    }

    setAuthUser = (user) => {
        this.user = user;
    };
}

export default UserStore;