import styled from "styled-components";
import {useForm} from "react-hook-form";
import {vestResolver} from '@hookform/resolvers/vest';
import {loginScheme} from "./schemes";
import FormError from "../../components/FormError";

import {fadeInSignInContainerFromBottom, fadeInSignInLogoFromTopAnimation} from "../../styles/animations";

import {FcGoogle} from "react-icons/fc";
import logo from '../../assets/logo2.png';

import {Container} from "../../containers/Container";
import Button from "../../components/Button";
import Input from '../../components/Input';
import Form from "../../containers/Form";
import SocialReactIcon, {StyledSocialReactIcon} from "../../components/Socials/SocialReactIcon";
import {StyledContainer} from "../../containers/Container/Container";
import {StyledButton} from "../../components/Button/Button";
import {useObserver} from "mobx-react-lite";
import {useRootStore} from "../../store/root.store";
import {Link, useNavigate} from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import {setNewUser} from "../../db/userDB";
import {errorMessages} from "../../firebase";

const StyledLogin = styled.div`
		font-size: 1.4rem;
  ${StyledContainer} {
			box-shadow: none;
		}
		
  .login-container {
    opacity: 0;
    position: relative;
    overflow: initial;
    width: 400px;
    min-height: 500px;
    display: flex;
    justify-content: space-between;
    animation: ${fadeInSignInContainerFromBottom} .7s .2s forwards ease-in-out;
				box-shadow:  0 0 0 19px rgba(255,146,1, .5);
				transition: all .3s;

    .logo {
      z-index: 1;
      opacity: 0;
      position: absolute;
      width: 74px;
      left: 50%;
      animation: ${fadeInSignInLogoFromTopAnimation} 1s 1s forwards ease-out;
    }

    .form-container {
						padding: 40px 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
						text-align: center;

      & > * {
        padding: 20px;
      }

      .para {
        padding: 10px 0 0;
      }
    }
  }

  .social-container {

    ${StyledButton} {
      width: 100%;
      border-radius: 0;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover ${StyledSocialReactIcon} {
        right: 88%;
        left: 0;
      }

      ${StyledSocialReactIcon} {
        position: absolute;
        left: 20px;
        right: 20px;
        top: 0;
        bottom: 0;
        background-color: white;
        display: inline-block;
        transition: all .5s ease-out;
      }

      &.google {
        background-color: #4285f4;
        border: 2px solid #4285f4;
      }
    }
  }
		
		.register-link {
				text-align: center;
		}
		
		@media only screen and (max-width: 500px) {
				.login-container {
						width: 90%;
						margin: 0 auto;
      box-shadow:  0 0 0 10px rgba(255,146,1, .5);
				}
		}
`;

export default function Login() {
	const navigate = useNavigate();
	const { signupWithGoogle, signInWithEmailAndPassword } = useAuth();
	const {userStore} = useRootStore();
	const {register, handleSubmit, setError, formState: {errors}} = useForm({
		resolver: vestResolver(loginScheme)
	});

	const onSubmit = async values => {
		const {email, password} = values;
		try {
			const user = await signInWithEmailAndPassword(email, password);
			const {user: {providerData, metadata, uid: userId}} = user;

			await setNewUser(userId, {
				...providerData[0], ...metadata,
			});
		} catch (err) {
			if (err && err.code) {
				setError("email", {message: errorMessages[err.code]});
			}
		}
	}

	return useObserver(() => {
		if (userStore.user) {
			navigate('/');
		}

		return (
			<StyledLogin>
				<Container className={"login-container"}>
					<img className='logo' src={logo} alt='logo'/>
					<Container
						className="form-container">
						<h1>Login</h1>
						<div className="social-container">
							<Button className='google' onClick={signupWithGoogle}>
								<SocialReactIcon icon={FcGoogle}/>
								<span>Sign up with google</span>
							</Button>
						</div>

						<p className='para'>or use your email for login</p>
						{errors && (<div style={{textAlign: "left"}}>
							{Object.values(errors).map(({message, index}) => <FormError key={index} message={message}/>)}
						</div>)}
						<Form onSubmit={handleSubmit(onSubmit)}>
							<Input {...register('email')} placeholder="Email" autoComplete='off'/>
							<Input {...register('password')} type="password" placeholder="Password" autoComplete='off'/>
							<Button type='submit'>Login</Button>
						</Form>
					</Container>

					<Container className={"register-link"}>
						<Link to={"/register"}>
							<p>Click here to register</p>
						</Link>
					</Container>
				</Container>
			</StyledLogin>
		)
	});
}
